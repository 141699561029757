.products-description {
  color: var(--gray);
  text-align: justify;
}

.choose-products {
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--main-color);
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
}

.product {
  cursor: pointer;
  width: fit-content;
  max-width: 400px;
  position: relative;
}

.products-container > .product:last-child {
  margin-right: 0;
}

.unselected-product {
  color: var(--gray);
  font-size: 25px;
  transition: color 0.5s, letter-spacing 0.5s;
}

.selected-product {
  color: var(--main-color);
  font-size: 25px;
  letter-spacing: 0.2px;
  font-weight: bold;
  transition: color 0.5s, letter-spacing 0.5s;
}

.product-description {
  color: var(--gray);
  text-align: justify;
}

.image-container {
  position: relative;
}

.filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 4px);
  opacity: 0.6;
  transition: background-color 0.5s;
}

.blue-filter {
  background-color: var(--main-color);
}

.product-image {
  width: 100%;
  height: auto;
}

.product-disabled {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  z-index: 2;
}

.image-disabled {
  filter: grayscale(100%);
}

@media all and (min-width: 750px) {
  .product {
    margin-right: 30px;
  }
  .products-container {
    align-items: initial;
    flex-wrap: nowrap;
  }
  .product-description, .products-description {
    font-size: 16px;
  }
  .selected-product, .unselected-product {
    margin: 35px 0 20px 0;
  }
}

@media all and (max-width: 749px) {
  .product {
    margin-bottom: 20px;
  }
  .products-container {
    align-items: center;
    flex-wrap: wrap;
  }
  .product-description, .products-description {
    font-size: 13px;
  }
  .selected-product, .unselected-product {
    margin: 18px 0 10px 0;
  }
}