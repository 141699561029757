.additional-notes-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.additional-notes {
  color: var(--main-color);
  margin-top: 60px;
  font-size: 19px;
}

.additional-notes-description {
  color: var(--gray);
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.notes-area {
  width: 100%;
  padding: 15px;
  font-size: 16px;
}
.additional-notes--comments {
  flex: 3;
}

.distributor-parameters {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.distributor-parameters > * {
  flex: 40%;
  margin-right: 16px;
  margin-top: 16px;
}

.param-title {
  color: var(--main-color);
}