.templates-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.template-image {
  height: 100%;
  display: block;
}

.template-item {
  cursor: pointer;
  padding-bottom: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  transition: background-color 0.5s;
  font-family: 'Open Sans SemiBold';
  height: 100%;
}

.templates-container > .template-item:last-child {
  margin-bottom: 0;
}

.unselected-template {
  color: var(--main-color);
}

.selected-template {
  color: white;
  background-color: var(--main-color);
}

.horizontal-line {
  color: gray;
  width: 300px;
  height: 100px;
  z-index: 100;
}

.parameters-container {
  display: flex;
  flex-wrap: wrap;
}

.parameters-container > * {
  flex: 46%;
}

.row-color {
  background-color: blue;
}

.parameter-label {
  font-family: 'Open Sans SemiBold';
}

#dialog-select {
  margin-top: 30px;
}

@media all and (min-height: 750px) {
  .template-item {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
