.image-upload {
    text-align: right;
    padding: 0;
}

.image-upload input {
    display: none;
}

.image-upload label {
    background-color: var(--main-color);
    color: #fff;
}

.image-preview {
    text-align: center;
}

.image-preview img {
    max-height: 200px;
}

.image-preview .nav-button {
    background-color: var(--danger);
}