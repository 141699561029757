.content-alert {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 100px;
    min-width: 250px;
    color: #ffffff;    
}

.content-confirm, .content-message {
  background-color: var(--main-color);
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.content-message {
  padding-top: 35px;
}

.open-modal-button,
.close-button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  margin-right: 8px;
  padding: 0;
}

.close-button {
  color: #ffffff;
  margin-right: 5px;
}

.close-button::after {
  position: absolute;
  content: '\d7';
  right: 5px;
  font-size: 30px;
  color: #ffffff;
}

.button-alert {
  min-width: 150px;
  min-height: 35px;
  background-color: #ffffff;
  border-radius: 5px;
  color: #0080e1;
  padding: 0;
}

.button-general {
  min-width: 150px;
  min-height: 35px;
  background-color: #0080e1;
  border-radius: 5px;
  color: #ffffff;
  padding: 0;
  margin-top: 10px;
}

.button-message {
  min-width: 150px;
  min-height: 35px;
  color: #0080e1;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 0;
  margin-top: 10px;
}

.image-preview-alert {
  max-width: calc(85vw - 10px);
  max-height: calc(85vh - 10px);;
}

.content-image{
  border-radius: 15px;
  padding: 25px;
  background-color: var(--light);
  border: var(--main-color) 1px solid;
  text-align: center;
}