.login-container {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
}

.login {
  color: var(--main-color);
  font-size: 24px;
  font-weight: bold;
}

.credentials {
  border: 1px solid black;
  height: 24px;
  width: 100%;
}

.username {
  margin-top: 24px;
}

.password {
  margin-top: 16px;
  margin-bottom: 24px;
}

.modal--background {
  background-color: rgb(10,10,10,0.2);
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: 9999;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  padding: 20px;
}

.login-modal-button {
  outline: none;
  color: white;
  border: none;
  font-size: 13px;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--main-color);
  cursor: pointer;
  border-radius: 7px;
}

.close-button {
  width: 100%;
  padding: 0;
  text-align: right;
  color: var(--gray);
}

.error-message {
  color: red;
}
