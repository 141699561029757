@import "product_form.css";

.cielo-product-form-wrapper .modal-header .close {
  margin-top: -2px;
  position: absolute;
  left: 95%;
}
  
.cielo-product-form-wrapper .nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: block;
}