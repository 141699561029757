.orange-text {
  color: var(--orange);
}
.project-info--container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto;
  max-width: var(--max-width);
}

.label {
  color: var(--main-color);
  text-align: left;  
}

.project-info--container .label:first-child {
  margin: 0;
}

.product-info--container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  margin-top: 50px;
}

.product-info-detail--container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
}

.product-parameters--container {
  display: flex;
  flex-direction: column;
}
.product-parameter{
  margin-bottom: 17px;
}

.element {
  display: inline-block;
  white-space: initial;
  word-wrap:break-word;
  overflow: visible;
}

#cost-small, #cost-normal {
  display: none;
}

@media all and (min-width:576px)  {
  #additionalNotesContainter {
    max-height: 200px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #additionalNotesContainter:hover {
    display: block;
    max-height: none;
    overflow: visible;
  }
}

@media all and (min-width:768px)  {
  #cost-normal {
    display: block;
  }
}

@media all and (max-width:767px)  {
  #cost-small {
    display: block;
  }
}

.uploaded-image {
  text-align: center;
}

.uploaded-image img {
  max-height: 25vh;
  max-width: 25vw;
}