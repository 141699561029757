.login-user {
  display: inline-block;
  color: var(--main-color);
}

.exit-button {
  display: inline-block;
  padding-right: 10px;

}

.login-button button {
  padding: 5px 0;
}

@media all and (max-width: 859px) {
  #logout-text {
    display: none;
  }
}