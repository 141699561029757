.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.cost-text {
  font-size: 12px;
  color: var(--main-color);
  margin: 0;
  padding-bottom: 5px;
}
.cost-number {
  font-weight: bold;
  font-size: 20px;
  margin: 0;
}
.line-container{
  margin:0;
}