div.color-box {
  width: 13px;
  height: 13px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  border: 2px solid black;
}

div.color-container {
  white-space: nowrap;
}

div.color-name {
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
}

div.side-name {
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
}

div.select-colors-box--container {
  margin-bottom: 0px;
}

.custom-input {
  width: 100%;
  border: 1px solid lightgray;
  outline: none;
  height: 30px;
  font-size: 16px;
  padding-left: 10px;
}

.feedback-message {
  color: red;
  font-size: 12.4px;
  margin-left: 5px;
  margin-top: 0px;
}

.invalid-input {
  border: 1px solid red;
}

.parameter-label {
  color: var(--main-color);
}
.mandatory-field {
  color: var(--red);
}

.form-input {
  min-width: 220px;
  
  margin-bottom: 27px;
}
.md-col-6 {
  max-width: 50%;
}
.md-col-12 {
  width: 100%;
}

.divider-container {
  display: flex;
  justify-content: space-between;
}

.divider-check {
  font-size: 17px;
}