.nav-buttons-container {
  display: flex;
}

.nav-button {
  margin-top: 20px;
  margin-right: 10px;
  width: fit-content;
  max-width: 380px;
  height: 30px;
  border-radius: 7px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 6px;
  color: white;
}

.next-button {
  background-color: var(--main-color);
}

.back-button {
  background-color: var(--gray);
}

.available-button {
  cursor: pointer;
}

.unavailable-button {
  cursor: not-allowed;
  opacity: 0.5;
}

@media all and (max-width: 859px) {
  .nav-button {
    margin-top: 5px;
  }
}

@media all and (min-width: 860px) {
  .nav-button {
    margin-top: 10px;
  }
}