:root {
  --max-width: 1300px;
  --main-color: #0080e2;
  --background-color: #ffffff;
  --app-container-color: #ffffff;
  --gray: #4b4b4b;
  --red: #FF0000;
  --orange: #FF6A06;
  --green: #adce46;
}

button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  padding-bottom: 30px;
}

.header-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@font-face {
  font-family: 'Segoe UI';
  src: local('Segoe UI'), url(../fonts/segoeui.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('../fonts/opensansbold700-webfont.woff2') format('woff2'),
       url('../fonts/opensansbold700-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Regular';
  src: url('../fonts/opensansregular400-webfont.woff2') format('woff2'),
       url('../fonts/opensansregular400-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src: url('../fonts/opensanssemibold600-webfont.woff2') format('woff2'),
       url('../fonts/opensanssemibold600-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Patua One';
  src: url('../fonts/patuaone400-webfont.woff2') format('woff2'),
       url('../fonts/patuaone400-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


* {
  font-family: 'Open Sans Regular';
}

.font-title {
  font-family: 'Patua One';
}

.font-bold {
  font-family: 'Open Sans Bold';
}

.font-semibold {
  font-family: 'Open Sans SemiBold';
}

body {
  background-color: var(--background-color);
}

#root {
  width: auto;
  margin: auto;
  max-width: var(--max-width);
  background-color: var(--app-container-color);
}

.loading-container {
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  opacity: 0.8;
  position: absolute;
  width: 65vw;
}
.loading {
  -webkit-transform: translate(-50%, -50%);
}

#cielo-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

#nav-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1 100%;
}

#btn-menu {
  display: none;
}

.btn-logo {
  cursor: pointer;
  z-index: 15;
  position: absolute;
  top: -40px;
  transition: all 0.5s;
}

#show-hide-button {
  border: 1px solid var(--main-color);
  height: 25px;
  width: 25px;
}

#show-hide-button[data-edit-text]::after {
  content: attr(data-edit-text);
  font-size: 12px;
  position: absolute;
  top: 7px;
  left: 30px;
  color: gray;
  width: 100px;
}

#arrow {
  position: absolute;
  top: 7px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-top: 2px solid black;
  border-right: 2px solid black;
  transform: rotate(45deg);
  border-radius: 15%;
}

#parameters {
  position: absolute;
  top: 0px;
  left: -110vw;
  width: 95vw;
  z-index: 10;
  transition: all 0.5s;
  opacity: 0.98;
  background: white;
}

#model-container {
  width: 95vw;
  position: absolute;
  left: 0px;
}

#btn-menu:checked ~ * #parameters {
  left: 0px;
  transition: all 0.5s;
}
#btn-menu:checked ~ * .btn-logo {
  left: calc(95vw - 20px);
  transition: all 0.5s;
}

#btn-menu:checked ~ * #arrow {
  transform: rotate(225deg);
  left: 9px;
}

#btn-menu:checked ~ * #show-hide-button::after {
  content: none;
}

#btn-menu:checked ~ * #show-hide-button[data-see3d-text]::before {
  content: attr(data-see3d-text);
  text-align: center;
  font-size: 12px;
  position: absolute;
  top: 7px;
  left: -140px;
  color: gray;
  width: 150px;
}

.app-title {
  color: var(--gray);
  font-size: 32px;
}

.pname-header{
  font-size: 27px;
  color: #0081e2;
  font-weight: bold
}

.floating-container {
  position: fixed;
  bottom: 15px;
  left: 50%;
  z-index: 11;
  width: 100vw;
  margin-left: -45vw;
}

.cost-notes {
  font-size: 12px;
}

.cost-notes::before {
  content: '*';
  padding-right: 5px;
  color: red;
  font-size: 18px;
}

.cost-notes-summary {
  width: 100% !important;
}

.disabled-button {
  opacity: 0.5;
}

@media all and (max-width: 859px) {
  #root {
    padding: 0 5px 0 5px;
  }
  header p {
    margin: 0;
    font-size: 13px;
  }    
  .app-title button {
    padding: 0;
    width: 30px;
  }
  .app-title img {
    vertical-align: center;
    height: 20px;
    margin-top: -5px;
  }
  .products-container {
    display: flex;
    justify-content: center;
  }
  .choose-products {
    text-align: center;
  }
  .products-description {
    padding: 0 30px 0 30px;
  }
  .nav-buttons-container {
    justify-content: center;
  }
  .dump-left-space {
    display: none;
  }
  #nav-buttons {
    margin-top: 9px;
    flex: auto;
  }
  .dump-left-container {
    padding-bottom: 5px;
    justify-content: center;
  }
  #main-content {
    position: relative;    
    top: 50px;
    min-height: calc(85vh - 250px);
  }
  .pname-header {
    text-align: center;
  }
  #model-container {
    height: 100%;
  }
  .cost-space {
    margin-top: 50px;
  }
  #parameters {
    height: 100%;
  }
}

@media all and (min-width: 860px) {
  .btn-logo {
    display: none;
  }

  #main-content,
  #model-container,
  #parameters {
    position: static;
  }

  #parameters {
    width: 37%;
    transition: none;
    height: auto;
  }

  #model-container {
    width: 63%;
    top: 0px;
    display: flex;
    align-items: stretch;
  }

  #main-content {
    display: flex;
    flex-direction: row;
    position: relative;
    width: auto;
    margin-top: 16px;
    min-height: calc(90vh - 250px);
  }

  #root {
    padding-left: 16px;
    padding-right: 16px;
  }

  .app-title button {
    padding: 0;
    width: 50px;
  }
  .app-title img {
    vertical-align: top;
    height: 30px;
    margin-top: 5px;
  }
  .dump-left-space {
    margin-right: 40px;
  }
  .cost-container {
    width: 38%;
    float: left;
  }
  .cost-notes {
    width: 61%;
    float: right;
    padding: 0 20px;
  }
}

@media all and (min-width:1253px)  {
  .dump-left-space {
    margin-right: 20px;
  }
}
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.app-title img {
  text-align: left;
}

.dump-left-container {
  display: flex;
}

.dump-left-space {
  height: 60px;
  width: 37%;
}

.clean {
  clear: both;
  padding: 0;
  margin: 0;
  flex: 100%;
}

.clear-both {
  clear: both;
  padding: 0;
  margin: 0;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.no-margin {
  margin: 0;
}