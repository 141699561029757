.check-control {
  font-size: 20px;
}

.check-container h4{
  width: 50%;
  float: left;
}

.check-container .check-control{
  width: 50%;
  float: left;
}

@media all and (max-width: 950px) {
  .form-container {
    width: 100%;
  }
  .check-container {
    width: 100%;
  }
  .check-container h4{
    width: 100%;
    float: none;
  }
  .check-container .check-control{
    width: 100%;
    float: none;
  }
}

@media all and (min-width: 951px) {
  .form-container {
    width: 80%;
  }
  .check-container {
    width: 80%;
  }
}

p {
  text-align: justify;
}